// import ReactPlayer from 'react-player'
import Image from "next/image";

// import Swiper core and required modules
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

import slider1 from "../../../public/assets/img/pmha-landing-image.jpg";

export default () => {
    return (
        <section className="bg-black h-full relative overflow-hidden">

            <Swiper
                modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                // spaceBetween={50}
                slidesPerView={1}
                // centeredSlides={true}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: false,
                }}
                loop={true}
                // navigation
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
                className={"swiper-slider-1"}
            // navigation={{
            //     nextEl: '.swiper-button-next',
            //     prevEl: '.swiper-button-prev',
            // }}
            >
                <SwiperSlide>
                    {/* <div className="swiper-slide-body z-20 inset-0 absolute"></div> */}
                    <div className="
                        bg-[url('/assets/img/pmha-landing-image.jpg')] bg-cover bg-center bg-no-repeat
                        relative overflow-hidden fluid-video-wrap min-h-[90vh] pt-16 lg:pt-0">
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    {/* <div className="swiper-slide-body z-20 inset-0 absolute"></div> */}
                    <div className="absolute inset-0 bg-blue opacity-80 z-10"></div>
                    <div className="
                        bg-[url('/assets/img/PMHA-Facade-Orig.jpg')] bg-cover bg-center bg-no-repeat grayscale
                        relative overflow-hidden fluid-video-wrap min-h-[90vh] pt-16 lg:pt-0">
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    {/* <div className="swiper-slide-body z-20 inset-0 absolute"></div> */}
                    <div className="absolute inset-0 bg-blue opacity-80 z-10"></div>
                    <div className="
                        bg-[url('/assets/img/PMHA-slider-4.jpg')] bg-cover bg-center bg-no-repeat grayscale
                        relative overflow-hidden fluid-video-wrap min-h-[90vh] pt-16 lg:pt-0">
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    {/* <div className="swiper-slide-body z-20 inset-0 absolute"></div> */}
                    <div className="absolute inset-0 bg-blue opacity-80 z-10"></div>
                    <div className="
                        bg-[url('/assets/img/IMG_2553.jpg')] bg-cover bg-center bg-no-repeat grayscale
                        relative overflow-hidden fluid-video-wrap min-h-[90vh] pt-16 lg:pt-0">
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    {/* <div className="swiper-slide-body z-20 inset-0 absolute"></div> */}
                    <div className="absolute inset-0 bg-blue opacity-80 z-10"></div>
                    <div className="
                        bg-[url('/assets/img/IMG_8711.jpg')] bg-cover bg-center bg-no-repeat grayscale
                        relative overflow-hidden fluid-video-wrap min-h-[90vh] pt-16 lg:pt-0">
                    </div>
                </SwiperSlide>
                
            </Swiper>



        </section>

    );
};