import BaseTemplate from '../src/layouts/BaseTemplate'
import HomeView from '../src/view/_public/HomeView';


export default function Home(props) {
  // const { data, status } = useQuery('users', getUsers, { initialData: props.users })
  // console.log("data", data)
  // console.log("status", status)

  // console.log("homeprops test", props)
  
  return (
    <BaseTemplate title="Home" body={<HomeView />} />
    // <BaseTemplate title="Home" body={<Dashboard />} />
  )
}
