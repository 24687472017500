import CountAnimate from "@/kyna/ui/animated/CountAnimate"

const Stats = () => {
    return (
        <>

            {/* Stats Section*/}
            <section className="bg-gray-50 my-24">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center">
                    <div className="col-span-1">
                        <div className="bg-[url('/assets/img/pmha-photo-1.jpg')] bg-center bg-cover bg-no-repeat lg:min-h-[550px] sm:min-h-[450px] min-h-[350px]"></div>
                    </div>
                    <div className="col-span-1 py-14 lg:pl-14 sm:pl-10 pl-8 lg:pr-[10%] sm:pr-10 pr-8">
                        <div className="grid sm:grid-cols-2 grid-cols-1 gap-10">
                            <div className="col-span-1">
                                <h2 className='mb-4 text-blue text-3xl xl:text-5xl xl:max-w-sm inline'><CountAnimate n={14}  />&nbsp;Million</h2>                         
                                <p className='pt-4 border-t border-t-gray-300 xl:max-w-sm'>people reached through  multimedia platforms</p>
                            </div>
                            <div className="col-span-1">
                                <CountAnimate n={150} delay={.3} />                      
                                <p className='pt-4 border-t border-t-gray-300 xl:max-w-sm'>socially conscious pool of professional volunteers</p>
                            </div>
                            <div className="col-span-1">
                                <CountAnimate n={1200} delay={.5} />                         
                                <p className='pt-4 border-t border-t-gray-300 xl:max-w-sm'>professional members</p>
                            </div>
                            <div className="col-span-1">
                                <CountAnimate n={300000} delay={1} />                          
                                <p className='pt-4 border-t border-t-gray-300 xl:max-w-sm'>key stakeholders</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>


    )
}
  
export default Stats