import { animate } from "framer-motion";
import{ useEffect, useRef, useState } from "react";

const useIntersectionObserver = (ref, handler, threshold = 0, rootMargin = "0px") => {
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          ref.current.textContent = 0;
          handler();
        }
      }, {
        rootMargin,
        threshold
      });
  
      if (ref.current) {
        observer.observe(ref.current);
      }
  
      return () => {
        observer.disconnect();
      };
    }, [ref, handler, rootMargin, threshold]);
  };


  const Counter = ({from, to, delay}) => {
    const nodeRef = useRef(null);
    useIntersectionObserver(nodeRef, () => {
      const controls = animate(from, to, {
        duration: 3,
        delay: delay ? delay : 0,
        ease: "easeOut",
        onUpdate(value) {
          if(nodeRef?.current?.textContent){
            nodeRef.current.textContent = Math.floor(value).toLocaleString('en-US');
          }
        }
      });
      return () => controls.stop();
    }, .3, "0px 0px 0px 0px");

    return <h2 className='mb-4 text-blue text-3xl xl:text-5xl xl:max-w-sm inline' ref={nodeRef} />
}

const CountAnimate = ({from=0, n, delay}) => {
  const [to, setTo] = useState(n);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTo(n);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [to]);

  return <Counter from={from} to={to} delay={delay} />;
}

export default CountAnimate
