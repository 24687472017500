import KNToasterIcons from './KNToasterIcons';
import PropTypes from 'prop-types';

import toast, { Toaster, ToastBar } from 'react-hot-toast';

const KNToaster = (props) => {
  const visibleCloseBtn = props?.visibleCloseBtn ? props.visibleCloseBtn : false;

  return(
    <Toaster toastOptions={{
        style: {
          maxWidth: 500
        }
      }}>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && visibleCloseBtn ? (
                <button onClick={() => toast.dismiss(t.id)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              ) : null}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}


KNToaster.propTypes = {
  visibleCloseBtn: PropTypes.bool,
};

export default KNToaster;