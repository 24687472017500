import PropTypes from 'prop-types';

const ToasterIcons = (props) => {
    switch (props.type) {
        case 'info':
          return (
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full bg-cyan-100 sm:mx-0 sm:h-8 sm:w-8">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-cyan-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>
          )
        
        case 'error':
          return (
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full bg-red-100 sm:mx-0 sm:h-8 sm:w-8">
                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
            </div>
          )
        default:
            return(
                <>
                </>
            )
        
    }
}


ToasterIcons.propTypes = {
    type: PropTypes.string.isRequired,
};

export default ToasterIcons;